import axios from "axios";
// import store from '@/store';
import { Toast } from "vant";
const baseURL =
  process.env.NODE_ENV == "development"
    ? "/api/"
    : "/prod-api";
// create an axios instance

const service = axios.create({
  baseURL: baseURL, // http://localhost:21021/
  // withCredentials: true, // send cookies when cross-domain requests
  //timeout: 5000 // request timeout
  //`xsrfCookieName`是要用作 xsrf 令牌的值的cookie的名称
  xsrfCookieName: "XSRF-TOKEN", // default
  // `xsrfHeaderName`是携带xsrf令牌值的http头的名称
  xsrfHeaderName: "X-XSRF-TOKEN", // default
});

// request interceptor
service.interceptors.request.use(
  (config) => {
    let token = getToken();
    // let token = "230aa526d67d408199633018ab841c60";
    if (token != undefined && token != "") {
      config.headers["Authorization"] = token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  (response) => {
    const res = response.data;
    if (res.__abp) {
      return handleResponse(response);
    }
    // if the custom code is not 20000, it is judged as an error.
    if (response.status !== 200) {
      Toast("系统繁忙，请稍后再试");

      // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
      if (res.code === 50008 || res.code === 50012 || res.code === 50014) {
        // to re-login
      }
      return Promise.reject(new Error(res.msg || "Error"));
    } else {
      if (!response.data) {
        // Toast(res.msg || "Error");
        Toast("系统繁忙，请稍后再试");
        return Promise.reject(new Error(res.msg || "Error"));
      } else {
        return res;
      }
    }
  },
  (error) => {
    // console.log('response error =>', error.response) // for debug
    if (error.response.data.__abp) {
      return handleResponse(error.response);
    }
    Toast(error.message);
    return Promise.reject(error);
  }
);

/**
 * 请求结果重新组织
 * 参考 Abp 的 js 是 abp.ng.js
 * @param {*} response
 */
function handleResponse(response) {
  var originalData = response.data;
  if (originalData.success === true) {
    response.data = originalData.result;
    return response;
  } else if (originalData.success === false) {
    // var messagePromise = null

    if (response.status == 401 && response.config.abpHandleError !== false) {
      // abp.ng.http.handleUnAuthorizedRequest(messagePromise, originalData.targetUrl);
      // 会话过期，跳转到登录页
      // store.dispatch('resetToken').then(_ => {
      //     router.push(`/login?redirect=${location.hash.replace('#', '')}${location.search}`).catch(() => { })
      // })
      // originalData.error.message = '为了您的帐号安全，请重新登录';
    }

    if (originalData.error) {
      if (response.config.abpHandleError !== false) {
        message.error(originalData.error.message || "请求出错了！！！");
      }
    } else {
      originalData.error = "";
    }

    response.data = originalData.error;
    return Promise.reject(response);
  } else {
    // not wrapped result
    return Promise.reject(response);
  }
}

function getToken() {
  if (localStorage.getItem("token")) {
    return localStorage.getItem("token");
  } else {
    return "";
  }
}
window.request = service;
export default service;
