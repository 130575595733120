import service from "./request";
// 查询日报统计
export function logReport_viewReport(data1, data2) {
  return service({
    url: `web/logReport/viewReport?deptId=${data1}&reportDate=${data2}`,
    method: "get",
  });
}
// 部门查询
export function dept_treeSelect(data1, data2) {
  return service({
    url: `web/dept/treeSelect`,
    method: "get",
  });
}
//asana授权
export function asana_login(data1, data2) {
  return service({
    url: `/system/user/setUserByCode?code=`+data1+"&asanaName="+data2,
    method: "get",
  });
}
export function asana_login1(data1, data2) {
  return service({
    url: `/web/user/setUserByCode?code=`+data1,
    method: "get",
  });
}
// 获取token
export function getToken(data) {
  return service({
    url: `/web/wx/getUserByCode?code=` + data,
    method: "get",
  });
}
// 获取token1
export function getToken1(data) {
  return service({
    url: `/qyLogin`,
    method: "post",
    data
  });
}
// 日报查询
export function submitLog(data) {
  return service({
    url: `/web/log/addLog`,
    method: "post",
    data: data,
  });
}
// 日报查询
export function LogInsert(data) {
  return service({
    url: `/web/log/insert`,
    method: "post",
    data: data,
  });
}
// 判断日清日结
export function LogInsert1(data) {
  return service({
    url: `/web/log/isClear`,
    method: "post",
    data: data,
  });
}
//查询日报详情
export function log_getLog(data1, data2) {
  return service({
    url: `web/log/getLog/${data1}`,
    method: "get",
  });
}
//根据日期查询日报
export function getLogByLogDate(data1) {
  return service({
    url: `web/log/getLogByLogDate/${data1}`,
    method: "get",
  });
}
// 查询日报质量
export function log_getLogQuality(data1, data2) {
  return service({
    url: `web/log/getLogQuality/${data1}/${data2}`,
    method: "get",
  });
}
// 获取下一工作日
export function log_getNextDay(data1) {
  return service({
    url: `web/log/getNextDate/${data1}`,
    method: "get",
  });
}
// 查询任务扭转
export function log_selectTurnTaskNameBy(data1, data2) {
  return service({
    url: `web/log/selectTurnTaskNameBy/${data1}/${data2}`,
    method: "get",
  });
}
// 提交日报耗时
export function log_submit(data1) {
  return service({
    url: `web/log/submit`,
    method: "post",
    data: data1,
  });
}
// 提交日报耗时
export function log_submitLog(data1) {
  return service({
    url: `web/log/submitLog`,
    method: "post",
    data: data1,
  });
}
// 查询人物详情
export function system_user(data1) {
  return service({
    url: `system/user/${data1}`,
    method: "get",
  });
}
// 查询公司详情
export function getCompanyInfo(data1) {
  return service({
    url: `system/company/${data1}`,
    method: "get",
  });
}
// 查任务查询
export function task_getTaskByTaskId(data1) {
  return service({
    url: `web/task/getTaskByTaskId/${data1}`,
    method: "get",
  });
}
//评论
export function task_story(data) {
  return service({
    url: `web/task/story`,
    method: "post",
    data: data,
  });
}

export function updateParam(data) {
  return service({
    url: `/update`,
    method: "post",
    data: data,
  });
}
