<template>
  <div class="rediect">

  </div>
</template>

<script>
import { getToken1 } from "./../utils/api";
export default {
    created() {
        // debugger
        if (JSON.stringify(this.$route.query) == "{}") {
            location.href="https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxf54d14636e137fd2&redirect_uri=http%3A%2F%2Fpmsh5.ilohhas.com%2Frediect&response_type=code&scope=snsapi_userinfo&state=123#wechat_redirect"
        // location.href =
            // "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxf54d14636e137fd2&redirect_uri=https://pmsh5.ilohhas.com/rediect&response_type=code&scope=snsapi_userinfo&agentid=wxf54d14636e137fd2&state=a123#wechat_redirect";
        }else{
            // if (this.$route.query.turnPath) {
            // if (!this.$route.query.code) {
            //          console.log(this.$route.query.code,'1')
            //         location.href = 'http://pmsadmin.ilohhas.com/?redirect=%2Findex%26turnPath%3Dfalse%26hCode%3D'+this.$route.query.code
            //      }else{
                    // location.href = 'http://pmsadmin.ilohhas.com/?redirect=%2Findex%26turnPath%3Dfalse%26hCode%3D'+this.$route.query.code
                    // location.href="https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxf54d14636e137fd2&redirect_uri=http%3A%2F%2Fpmsh5.ilohhas.com%2Frediect%3FturnPath%3Dfalse&response_type=code&scope=snsapi_userinfo&state=123#wechat_redirect"
                //  }
            // }else{
            //       console.log(this.$route.query.code,'3')
                location.href = 'http://pmsadmin.ilohhas.com/?redirect=%2Findex%26hCode%3D'+this.$route.query.code
            // }
        }
    },
    // watch: {
    //     $route(res) {
    //         if (res.query.code) {
    //             console.log(res,'oooo');
    //             location.href = 'http://pmsadmin.ilohhas.com/?redirect=%2Findex%26hCode%3D'+res.query.code
    //             // this.login(res.query.code);
    //         }
    //     },
    // },
    methods:{
        async login(code) {
            let that = this;
            getToken1({'code':code}).then((res) => {
                if (res.code == 200) {
                    localStorage.setItem("token", res.token);
                }
            });
        },
    }
}
</script>

<style>

</style>