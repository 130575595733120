<template>
  <div class="empty">
    <van-dialog class="btn1" :before-close="beforeClose1"  v-model="showConfirm">
      正在从企业微信同步信息
    </van-dialog>
    <van-dialog class="btn1" :before-close="beforeClose3"  v-model="showConfirm1">
      asana授权成功
    </van-dialog>
    <van-dialog class="btn1" :before-close="beforeClose5"  v-model="showConfirm2">
      asana授权成功
    </van-dialog>
    <van-dialog class="btn1" :before-close="beforeClose6"  v-model="showConfirm3">
      asana授权成功
    </van-dialog>
    <van-dialog v-model="showTip" title="" :before-close="beforeClose4"  confirmButtonColor="#3a68f2">
      进入后台需Asana授权，请授权后再进入
    </van-dialog>
     <van-dialog v-model="showTip1" title="" cancelButtonText="终止授权" show-cancel-button confirmButtonText="重新授权" :before-close="beforeClose2"  confirmButtonColor="#3a68f2">
      {{tip2}}
    </van-dialog>
  </div>
</template>

<script>
import { submitLog, LogInsert, getToken,getToken1,asana_login,asana_login1 } from "./../utils/api";
import { Dialog, Toast } from "vant";
export default {
    data() {
        return {
            showTip:false,
            showTip1:false,
            showConfirm:false,
            showConfirm2:false,
            showConfirm3:false,
            errorCode:"",
            showConfirm1:false,
            tip1:"",
            tip2:""
        }
    },
    created(){
        localStorage.removeItem('token')
        console.log(this.$route.query)
        if(this.isQyweixin()){
             console.log("isQyweixin")
            if (JSON.stringify(this.$route.query) == "{}") {
                    location.href =
                    "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxf54d14636e137fd2&redirect_uri=https%3A%2F%2Fpmsh5.ilohhas.com&response_type=code&scope=snsapi_userinfo&state=isWxCode#wechat_redirect";
            }else{
                if (this.$route.query.state != 'isWxCode') {
                    localStorage.setItem('asanaName',this.$route.query.state)
                    location.href =
                    "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxf54d14636e137fd2&redirect_uri=https%3A%2F%2Fpmsh5.ilohhas.com%2F%3FasnanaCode%3D"+this.$route.query.code+"&response_type=code&scope=snsapi_userinfo&state=isWxCode#wechat_redirect";

                }else {
                    if(this.$route.query.asnanaCode){
                        console.log(this.$route.query.asnanaCode)
                        let asanaName = ""
                        if(localStorage.getItem('asanaName')){
                            asanaName = localStorage.getItem('asanaName')
                        }
                        this.getLoginAsana(this.$route.query.code,this.$route.query.asnanaCode,asanaName)
                    }else{
                        this.login(this.$route.query.code);
                    }
                }
            }
        }else{
            // Dialog.alert({
            //   message: '请从PC端进入',
            //   confirmButtonColor:"#3a68f2"
            // }).then(() => {
            // });
            if (this.$route.query.state) {
                if (this.$route.query.state == 'isWxCode') {
                    this.getLoginAsana(this.$route.query.code,this.$route.query.asnanaCode)
                }
                else{
                    Dialog.alert({
                        message: '企业微信管理后台不支持手机端登录，请企业管理员从PC端进入',
                        confirmButtonColor:"#3a68f2"
                    }).then(() => {
                    });
                    // this.login(this.$route.query.code);
                }
            }else{
                if (JSON.stringify(this.$route.query) == "{}") {
                    location.href =
                        "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxf54d14636e137fd2&redirect_uri=https%3A%2F%2Fpmsh5.ilohhas.com&response_type=code&scope=snsapi_userinfo&state=isWxCode1#wechat_redirect";
                }else{
                    location.href =
                    "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxf54d14636e137fd2&redirect_uri=https%3A%2F%2Fpmsh5.ilohhas.com%2F%3FasnanaCode%3D"+this.$route.query.code+"&response_type=code&scope=snsapi_userinfo&state=isWxCode#wechat_redirect";
                }
            }
        }
    },
    components: {
        [Dialog.Component.name]: Dialog.Component,
    },
    methods:{
        beforeClose1 : function (action, done) { // 点击事件 - 备注按钮提示框
            if (action === 'confirm') { // 确认
            this.showTip = false;
            location.href = 'https://pmsh5.ilohhas.com/rediect'
            done(); // 关闭提示框
            } else if (action === 'cancel') { // 取消
            done(); // 关闭提示框
            }
        },
        beforeClose2 : function (action, done) { // 点击事件 - 备注按钮提示框
            if (action === 'confirm') { // 确认
                this.showTip1 = false;
                location.href = 'https://pmsh5.ilohhas.com/rediect'
                done(); // 关闭提示框
            } else if (action === 'cancel') { // 取消
                this.showTip1 = false;
                this.showTip = true;
                done(); // 关闭提示框

            }
        },
        beforeClose4: function (action, done) { // 点击事件 - 备注按钮提示框
            if (action === 'confirm') { // 确认
                this.showTip = false;
                location.href = 'http://pmsadmin.ilohhas.com/empty'
                done(); // 关闭提示框
            } else if (action === 'cancel') { // 取消
                done(); // 关闭提示框
            }
        },
        beforeClose3: function (action, done) { // 点击事件 - 备注按钮提示框
            if (action === 'confirm') { // 确认
            this.$router.replace('select')
            done(); // 关闭提示框
            } else if (action === 'cancel') { // 取消
            done(); // 关闭提示框
            }
        },
        beforeClose5: function (action, done) { // 点击事件 - 备注按钮提示框
            if (action === 'confirm') { // 确认
            this.$router.push({
                path:"selectDate",
            })
            done(); // 关闭提示框
            } else if (action === 'cancel') { // 取消
            done(); // 关闭提示框
            }
        },
        beforeClose6: function (action, done) { // 点击事件 - 备注按钮提示框
            if (action === 'confirm') { // 确认
            location.href = 'https://pmsh5.ilohhas.com/rediect'
            done(); // 关闭提示框
            } else if (action === 'cancel') { // 取消
            done(); // 关闭提示框
            }
        },
        async getLoginAsana(code,code1,code2){
            let that = this;
            this.showTip1 = false;
            //if(true){
            if(localStorage.getItem('isH5')=="1"){
                getToken(code).then((res) => {
                    alert(res.code)
                    alert(res.msg)
                    if (res.code == 200) {
                        localStorage.setItem("token", res.token);
                        asana_login1(code1).then((res1) => {
                            if (res1.code == 200) {
                                this.showConfirm2 = true
                            }else{
                                this.$router.push({
                                    path:"empty1",
                                    query:{
                                        content: encodeURIComponent(res1.msg)
                                    }
                                })
                            }
                        });
                    }else{
                        this.$router.push({
                            path:"empty1",
                            query:{
                                content: encodeURIComponent(res.msg)
                            }
                        })
                    }
                });
            }else{
                // Dialog.alert({
                //     message: '企业管理后台不支持手机端登录，请企业管理员从PC端进入',
                //     confirmButtonColor:"#3a68f2"
                // }).then(() => {
                // });
                if(this.isQyweixin()){
                    getToken1({"code":code}).then((res) => {
                        console.log(res,'PC')
                        if (res.code == 200) {
                            localStorage.setItem("token", res.data.token);
                            asana_login(code1,code2).then((res1) => {
                                console.log(res1,'PC res1')
                                if (res1.code == 200) {
                                    this.showConfirm3 = true;
                                }else{
                                    // if(res1.data){
                                    //     this.errorCode = res1.data
                                    // }
                                    this.tip2 = res1.msg
                                    this.showTip1 = true;
                                }
                            });
                        }else{
                            Dialog.alert({
                            message: res.msg,
                            }).then(() => {
                            location.href = 'http://pmsadmin.ilohhas.com/empty'
                            });
                        }
                    });
                }else{
                    getToken(code).then((res) => {
                        console.log(res,'Mobile')
                        if (res.code == 200) {
                            localStorage.setItem("token", res.token);
                            asana_login1(code1).then((res1) => {
                                if (res1.code == 200) {
                                    this.showConfirm1 = true;
                                }else{
                                    Dialog.alert({
                                    message: res1.msg,
                                    }).then(() => {
                                    });
                                }
                            });
                        }else{
                            Dialog.alert({
                                message: res.msg,
                            }).then(() => {
                            });
                        }
                    });
                }
            }
            },
            async login(code) {
                let that = this;
                getToken(code).then((res) => {
                    if (res.code == 200) {
                        localStorage.setItem("token", res.token);
                        localStorage.setItem("name", res.user.name);
                        // getInfo().then(res2=>{
                        if(res.user.companyId){
                            // getCompanyInfo(res.user.companyId).then(res1=>{
                            // if(res1.data.openPms=="1"){
                            //     Dialog.alert({
                            //     message: '企业未开通，请联系管理员',
                            //     }).then(() => {
                            //     });
                            // }else{
                            if(!res.user.asanaName){
                                location.href = 'https://app.asana.com/-/oauth_authorize?client_id=1201450570166924&redirect_uri=https://pmsh5.ilohhas.com&response_type=code'
                            }else{
                                this.$router.replace('select')
                            }
                            // }
                            // })
                        }else{
                            // Toast('该企业未开通，请联系管理员开通')
                            Dialog.alert({
                            message: '手机端不支持打开应用，请从电脑端打开应用',
                            }).then(() => {
                            });
                        }
                        // })
                    }else{
                        Dialog.alert({
                        message: '手机端不支持打开应用，请从电脑端打开应用',
                        }).then(() => {
                        });
                    }
                });
            },
        isQyweixin(){
        //判断当前入口是PC端还是APP端
            let flag = navigator.userAgent.match(
                /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
            )==null?false:true;

            return flag?this.is_weixin()?false:false:this.is_weixin()?true:false;
        },
        is_weixin(){
        //判断是在企业微信打开 还是 在浏览器打开
            return navigator.userAgent.toLowerCase().indexOf('micromessenger') !== -1
        },
    },
}
</script>
<style scope lang="less">
.empty {
  /deep/.van-dialog__content {
      padding: 20px!important;
  }
  .btn1{
    .van-dialog__content {
       text-align: left;
    }
    border-radius: 4px;
    padding: 10px;
    .van-dialog__footer{
        text-align: right;
        display: block;
    }
    .van-button{
        width: fit-content;
        flex: none;
        float: right;
        color: #fff;
        height: auto;
        background-color: #1890ff;
        padding: 10px 20px;
        border-radius: 3px;
        .van-button__text{
            font-size: 12px;
        }
    }
  }
 .van-dialog__content {
    padding: 20px!important;
  }
}
</style>